function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    console.error(e.message);
    return false;
  }
  return true;
}

export const localStorageHealthCheck = async () => {
  for (let i = 0; i < localStorage.length; i++) {
    try {
      const result = window.localStorage.getItem(localStorage.key(i));
      if (!isJsonString(result)) {
        window.localStorage.removeItem(localStorage.key(i));
      }
      if (result && Object.keys(localStorage.key(i)).length === 0) {
        window.localStorage.removeItem(localStorage.key(i));
      }
    } catch (error) {
      window.localStorage.clear();
      console.error(error.message);
    }
  }
};

export const storePersist = {
  set: (key, state) => {
    window.localStorage.setItem(key, JSON.stringify(state));
  },
  get: (key) => {
    const result = window.localStorage.getItem(key);
    if (!result) {
      return false;
    }
    if (!isJsonString(result)) {
      window.localStorage.removeItem(key);
      return false;
    } else return JSON.parse(result);
  },
  remove: (key) => {
    window.localStorage.removeItem(key);
  },
  getAll: () => {
    return window.localStorage;
  },
  clear: () => {
    window.localStorage.clear();
  },
};

export default storePersist;
