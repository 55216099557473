import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import storePersist from "./storePersists";

const AUTH_INITIAL_STATE = {
  current: {},
  isLoggedIn: false,
  isLoading: false,
  isSuccess: false,
};

const auth_state = storePersist.get("auth")
  ? storePersist.get("auth")
  : AUTH_INITIAL_STATE;

const initialSate = { auth: auth_state };

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialSate,
  // Thunk is included by default, no need to apply it separately
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true, // This is redundant because it's enabled by default, but you can keep it for clarity
    }),
});

export default store;
