import { combineReducers } from "redux";
import { reducer as authReducer } from "./auth/index";
import { reducer as crudReducer } from "./crud/index";
import { reducer as settingsReducers } from "./settings/index";

const rootReducer = combineReducers({
  auth: authReducer,
  crud: crudReducer,
  settings: settingsReducers,
});

export default rootReducer;
