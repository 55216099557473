export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const OPEN_ADVANCED_BOX = "OPEN_ADVANCED_BOX";
export const CLOSE_ADVANCED_BOX = "CLOSE_ADVANCED_BOX";

export const OPEN_EDIT_BOX = "OPEN_EDIT_BOX";
export const CLOSE_EDIT_BOX = "CLOSE_EDIT_BOX";

export const OPEN_PANEL = "OPEN_PANEL";
export const CLOSE_PANEL = "CLOSE_PANEL";
export const COLLAPSE_PANEL = "COLLAPSE_PANEL";

export const OPEN_BOX = "OPEN_BOX";
export const CLOSE_BOX = "CLOSE_BOX";
export const COLLAPSE_BOX = "COLLAPSE_BOX";

export const OPEN_READ_BOX = "OPEN_READ_BOX";
export const CLOSE_READ_BOX = "CLOSE_READ_BOX";
export const COLLAPSE_READ_BOX = "COLLAPSE_READ_BOX";
