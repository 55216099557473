import React from "react";
import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
  Image,
  CloseButton,
  Button,
  Collapse,
  Drawer,
  DrawerContent,
} from "@chakra-ui/react";

import { AiFillProduct } from "react-icons/ai";
import { FaFileInvoiceDollar, FaThList, FaUsers } from "react-icons/fa";
import { MdLeaderboard } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { BiSolidCategory } from "react-icons/bi";
import { GoHomeFill } from "react-icons/go";
import { RiUserSettingsFill } from "react-icons/ri";
import { FaAddressCard } from "react-icons/fa";
import { SiCoursera } from "react-icons/si";
import { FiMaximize2, FiMinimize2, FiSettings } from "react-icons/fi";
import { FaNoteSticky } from "react-icons/fa6";
import { Link } from "react-router-dom";

const LinkItems = [
  {
    name: "Dashboard",
    icon: GoHomeFill,
    href: "/dashboard",
  },
  { name: "Category", icon: BiSolidCategory, href: "/product-category" },
  {
    name: "Products",
    icon: AiFillProduct,
    href: "/products",
  },
  {
    name: "Leads",
    icon: MdLeaderboard,
    href: "/leads-table",
  },
  {
    name: "Order's list",
    icon: FaThList,
    href: "/order-table",
    dropdownItems: [
      { name: "Test Orders", icon: FaNoteSticky, href: "/test-table" },
      { name: "Card Orders", icon: FaAddressCard, href: "/card-table" },
      { name: "Course Orders", icon: SiCoursera, href: "/course-table" },
    ],
  },
  {
    name: "User's list",
    icon: FaUsers,
    href: "/user-list",
  },
  {
    name: "Invoice",
    icon: FaFileInvoiceDollar,
    href: "/invoice",
  },
  {
    name: "Settings",
    icon: RiUserSettingsFill,
    dropdownItems: [
      { name: "Admin List", icon: RiAdminFill, href: "/admin-list" },
      { name: "Website Settings", icon: FiSettings, href: "/website-settings" },
    ],
  },
];

export default function Sidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose()}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      zIndex={999}
      overflowY={"auto"}
      h="full"
      color={"#A5A3A3"}
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Link to={"/"}>
          <Image src={require("../assests/logo/Artboard 1.png")}></Image>{" "}
        </Link>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link, index) => (
        <Box key={index}>
          <NavItem
            icon={link.icon}
            children={link.name}
            href={link.href}
            dropdownItems={link.dropdownItems}></NavItem>
        </Box>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, dropdownItems, href, ...rest }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box
      as="div"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}>
      <Link to={href} style={{ textDecoration: "none", width: "100%" }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "#FF31312B",
            color: "#FF3131",
            textDecoration: "none",
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{ color: "#FF3131" }}
              as={icon}
            />
          )}
          {children}
          {dropdownItems && dropdownItems.length > 0 && (
            <Button
              bg="none"
              ml="auto"
              color={"gray"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onToggle();
              }}
              size="sm"
              _hover={{ bg: "none", color: "#FF3131" }}>
              {isOpen ? <FiMinimize2 /> : <FiMaximize2 />}
            </Button>
          )}
        </Flex>
      </Link>
      <Collapse in={isOpen}>
        {dropdownItems &&
          dropdownItems.map((item, index) => (
            <Flex
              key={index}
              p={4}
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{ bg: "#FF31312B", color: "#FF3131" }}
              alignItems={"center"}>
              {item.icon && <Icon mr="2" as={item.icon} />}
              <a href={item.href}>{item.name}</a>
            </Flex>
          ))}
      </Collapse>
    </Box>
  );
};
