export const FAILED_REQUEST = "AUTH_FAILED_REQUEST";
export const LOADING_REQUEST = "AUTH_LOADING_REQUEST";

export const LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";

export const LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "AUTH_LOGOUT_FAILED";

export const RESET_STATE = "AUTH_RESET_STATE";

export const REQUEST_LOADING = "AUTH_REQUEST_LOADING";
export const REQUEST_SUCCESS = "AUTH_REQUEST_SUCCESS";
export const REQUEST_FAILED = "AUTH_REQUEST_FAILED";
