import React from "react";
import {
  Box,
  Button,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { crud } from "../redux/crud/actions";
import { logout } from "../redux/auth/actions";
import { useLayoutEffect } from "react";
import { selectAuth, selectCurrentAdmin } from "../redux/auth/selectors"; // Import the selector for the current admin

export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSuccess } = useSelector(selectAuth);
  const currentUser = useSelector(selectCurrentAdmin); // Get the current user's data

  useLayoutEffect(() => {
    dispatch(crud.resetState());
  }, [dispatch]);

  const signOut = () => {
    dispatch(logout());
    if (isSuccess) navigate("/login");
  };

  return (
    <Flex
      p={4}
      w={"1175px"}
      position="fixed"
      top={0}
      left={0}
      right={0}
      marginLeft={{ base: 0, md: 60 }}
      h={"80px"}
      bg="white"
      zIndex="1"
      justifyContent="flex-end">
      <Box
        width={"25%"}
        display={"flex"}
        alignItems="center"
        justifyContent="flex-end">
        <Box display={"flex"} alignItems="center">
          <Link to={"/add-to-cart"}>
            <Button
              mr={4}
              fontSize={"sm"}
              variant={"outline"}
              h={"35px"}
              color={"white"}
              padding={"6px, 8px, 6px, 8px"}
              borderRadius={"8px"}
              bgColor={"rgb(229,62,62)"}>
              Create Order
            </Button>
          </Link>
          <Menu>
            <MenuButton as={Box} display="flex" cursor="pointer">
              <Avatar name={currentUser.name} src={currentUser.photo} />
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Link to="/user-profile">Profile</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/website-settings">Settings</Link>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Flex>
  );
}
