import * as actionTypes from "./types";
import * as authService from "../../auth/auth.service";
import request from "../../request/request";

export const login =
  ({ loginData }) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_LOADING,
      keyState: "login",
      payload: null,
    });

    let data = await authService.login({ loginData });
    if (data.success === true) {
      const auth_state = {
        current: data.result,
        isLoggedIn: true,
        isLoading: false,
        isSuccess: true,
      };
      window.localStorage.setItem("auth", JSON.stringify(auth_state));
      window.localStorage.removeItem("isLogout");
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data.result,
      });
    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
      });
    }
  };

export const logout = () => async (dispatch) => {
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
  });
  const result = window.localStorage.getItem("auth");
  const tmpAuth = JSON.parse(result);
  const settings = window.localStorage.getItem("settings");
  const tmpSettings = JSON.parse(settings);
  window.localStorage.removeItem("auth");
  window.localStorage.removeItem("settings");
  window.localStorage.setItem("isLogout", JSON.stringify({ isLogout: true }));
  const data = await authService.logout();
  if (data.success === false) {
    const auth_state = {
      current: tmpAuth,
      isLoggedIn: false,
      isLoading: false,
      isSuccess: true,
    };
    window.localStorage.setItem("auth", JSON.stringify(auth_state));
    window.localStorage.setItem("settings", JSON.stringify(tmpSettings));
    window.localStorage.removeItem("isLogout");
    dispatch({
      type: actionTypes.LOGOUT_FAILED,
      payload: data.result,
    });
  }
};

export const updateProfile =
  ({ entity, jsonData }) =>
  async (dispatch) => {
    let data = await request.updateAndUpload({ entity, id: "", jsonData });
    if (data.success === true) {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data.result,
      });
      const auth_state = {
        current: data.result,
        isLoggedIn: true,
        isLoading: false,
        isSuccess: true,
      };
      window.localStorage.setItem("auth", JSON.stringify(auth_state));
    }
  };
