import { API_BASE_URL } from "../config/serverAPIConfig";
import axios from "axios";
import successHandler from "../request/successHandler";
import errorHandler from "../request/errorHandler";

export const login = async ({ loginData }) => {
  try {
    const response = await axios.post(
      API_BASE_URL + `/login?timestamp=${new Date().getTime()}`,
      loginData
    );
    const { status, data } = response;
    successHandler(
      { data, status },
      { notifyOnFailed: true, notifyOnSuccess: true }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const logout = async () => {
  axios.defaults.withCredentials = true;
  try {
    const response = await axios.post(
      API_BASE_URL + `/logout?timestamp=${new Date().getTime()}`
    );
    const { status, data } = response;
    successHandler(
      { data, status },
      { notifyOnFailed: true, notifyOnSuccess: true }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};
